import React from 'react'
import { graphql } from 'gatsby'
import { Container, Badge } from 'reactstrap'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Icons from '../components/shared/Icons'
import Seo from '../components/shared/Seo'
// import icons
import { FaTwitter } from 'react-icons/fa'
import { FaFacebook } from 'react-icons/fa'
import { FaGooglePlus } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import { FaPinterest } from 'react-icons/fa'
import { FaLinkedin } from 'react-icons/fa'
// import react-custom-share components
import { ShareButtonRoundSquare, ShareBlockStandard } from 'react-custom-share'

export default class BlogSingle extends React.Component {
  render() {
    const {
      title,
      createdAt,
      slug,
      excerpt: { excerpt },
      content: {
        g: { html },
      },
      featuredImage,
      featuredImage: {
        file: { url: img },
      },
    } = this.props.data.post
    const shareBlockProps = {
      url: 'https://proteo.co.uk/blog/' + slug,
      button: ShareButtonRoundSquare,
      buttons: [
        { network: 'Twitter', icon: FaTwitter },
        { network: 'Facebook', icon: FaFacebook },
        { network: 'GooglePlus', icon: FaGooglePlus },
        { network: 'Email', icon: FaEnvelope },
        { network: 'Linkedin', icon: FaLinkedin },
      ],
      text: title,
      longtext: excerpt,
    }
    return (
      <Layout>
        <Seo
          title={title}
          image={img}
          url={'https://proteo.co.uk/blog/' + slug}
          type="Article"
          description={excerpt}
        />
        <Container className="py-5">
          <Badge color="primary py-2 px-5 mb-5 text-white">{createdAt}</Badge>
          <h1 className="mb-2">{title}</h1>
          <img
            alt={featuredImage.description}
            src={img}
            className="d-block mx-auto mw-100 my-5 border-40 "
          />
          <div
            className="text-justify blog-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />

          <ShareBlockStandard {...shareBlockProps} />
          <div className="text-center py-3">
            <Link
              to={`/news-blogs`}
              className="btn  primary-btn-gradient py-2 px-5 text-white "
            >
              Back
            </Link>
          </div>
        </Container>
        <div className="py-2" />
        <Icons />
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    post: contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      slug
      excerpt {
        excerpt
        g: childMarkdownRemark {
          html
        }
      }
      createdAt(formatString: "MMMM DD YYYY")
      featuredImage {
        description
        file {
          url
        }
      }
      content {
        g: childMarkdownRemark {
          html
        }
      }
    }
  }
`
